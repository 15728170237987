<template>
  <div class="aboutx">
    <!-- 标题 -->
    <div class="title w">
      <h5>{{title}}</h5>
      <div class="titleHeaders">
        <span>{{time}}</span>
        <p>{{$t('news.views')}}：{{visits}}</p>
      </div>
    </div>
    <!-- 文字 -->
    <div class="word w"
         v-html="content">
      <!-- <p>云南的单芽白茶算不算白毫银针？</p>
      <p>云南大叶种的白牡丹应该如何定级？</p>
      <p>云南白茶红变褐变的问题该如何解决？</p>
      <p>云南白茶有没有标准？</p> -->
    </div>
    <!-- 图片 -->
    <!-- <ul class="image w">
      <li>
        <img src="../assets/images/06.gif" alt />
        <p>
          在2019中国（深圳）国际茶产业博览会期间举办
          的——首届云南白茶高峰论坛，将会以专业的角度，
          给你专业的回答。论坛由云南省农业科学院茶叶研
          究所主办，深圳华巨臣实业有限公司承办，得到了
          中国茶叶学会、天津茶叶学会的支持。
        </p>
      </li>
      <li>
        <img src="../assets/images/06.gif" alt />
        <p>
          在2019中国（深圳）国际茶产业博览会期间举办
          的——首届云南白茶高峰论坛，将会以专业的角度，
          给你专业的回答。论坛由云南省农业科学院茶叶研
          究所主办，深圳华巨臣实业有限公司承办，得到了
          中国茶叶学会、天津茶叶学会的支持。
        </p>
      </li>
    </ul> -->
  </div>
</template>

<script>
import { getGreviousExhInfo } from '@/api/index.js';
export default {
  name: "AboutDet",
  data () {
    return {
      title: '',
      time: '',
      visits: 0,
      content: ''
    };
  },
  mounted () {

  },
  watch: {
    $route: {
      handler () {
        this.infoContent()
      },
      deep: true
    }
  },
  created () {
    this.infoContent();
  },
  methods: {
    infoContent () {
      let query = this.$route.query;
      getGreviousExhInfo({
        'id': query.id
      }).then(res => {
        if (res.code == '200') {
          this.title = res.data.name,
            this.time = res.data.create_time,
            this.visits = res.data.visits,
            this.content = res.data.content
        }
      })
    }
  }
};
</script>

<style  scoped lang="scss">
.aboutx {
  .title {
    h5 {
      padding-bottom: 20px;
    }
  }
}
.titleHeaders {
  text-align: center;
  span {
    margin-right: 40px;
  }
}
.title {
  margin-top: 63px;
  height: 66px;
  // background: violet;

  h5 {
    text-align: center;
    color: #565656;
    font-size: 24px;
  }

  i {
    display: inline-block;
    color: #999999;
    margin-top: 25px;
  }
  span {
    display: inline-block;
    color: #999999;
  }
  p {
    display: inline-block;
    color: #999999;
  }
}
.word {
  margin-top: 40px;
  padding-bottom: 40px;
  line-height: 32px;
  //  background: chartreuse;

  p {
    color: #333333;
    font-size: 16px;
    margin-bottom: 18px;
  }
}
.image {
  p {
    margin: 24px 0;
  }
}
::v-deep {
  strong {
    font: revert !important;
  }
}
</style>